import { loadable } from '@/store/mixins'

export default {
  namespaced: true,

  mixins: [
    loadable({ action: 'fetchApproval' }),
    loadable({ action: 'initApprovalProcess' }),
    loadable({ action: 'updateProcessByApprover' }),
    loadable({ action: 'saveComment' })
  ],

  state: {
    info: {},
    allDocuments: [],
    iterations: []
  },

  getters: {
    hasApprovalInfo(state) {
      return state.info?.id
    },

    // NOT REVIEWED

    pageTitle(state, getters) {
      return `${getters.approvalDocument?.version}. Согласование документа. ${getters.approvalDocument?.translated_type}`;
    },
    approvalInfo(state) {
      return state.info;
    },
    approvalDocument(state, getters) {
      const mainDocId = getters.currentIteration.document_id
      return state.allDocuments.find((doc) => doc.id === mainDocId);
    },
    allDocuments(state) {
      return state.allDocuments;
    },
    iterations(state) {
      return state.iterations;
    },
    currentIteration(state, getters) {
      return getters.iterations[state.iterations.length - 1];
    },
    currentApprover(state, getters) {
      return getters.currentIteration.approvers?.find(a => a.approve_status === 'on_review');
    },
    isGip(state, getters, rootState, rootGetters) {
      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_chief_project_engineer'] });
    },
    isApprover(state, getters, rootState, rootGetters) {
      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_document_approver'] });
    },
    isCreator(state, getters, rootState, rootGetters) {
      // если в актуальной итерации пользователь состоит в апруверах и процесс в статусе согласования, то убрать роль проектировщика
      const isApprover = getters.currentIteration?.approvers?.find(a => a.id === rootGetters['auth/profile'].id)?.id;
      if (isApprover && getters.approvalInfo?.process_status === 'on_review') return false;

      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_project_designer'] });
    }
  },

  mutations: {
    UPDATE_ALL_DOCUMENTS: (state, payload) => {
      state.allDocuments = payload;
    },
    UPDATE_INFO: (state, payload) => {
      state.info = payload;
    },
    UPDATE_ITERATIONS: (state, payload) => {
      state.iterations = payload.map((iteration, idx) => {
        const approvers = []
        let firstEditor = true;
        let isEnd = false;

        // add icon to approver
        iteration.approvers.forEach((a, idx) => {
          if (isEnd) return;

          if (!idx) {
            if (a.approve_status === 'stopped') return { ...a, icon: 'cross' };
            approvers.push({ ...a, icon: 'homeFilled' });
            return;
          }

          if (idx !== 0 && firstEditor && a.approve_status === 'on_review') {
            firstEditor = false;
            approvers.push({ ...a, icon: 'edit' });
            return;
          }

          if (a.approve_status === 'approved') {
            approvers.push({ ...a, icon: 'check' });
            return;
          }

          if (a.approve_status === 'not_approved') {
            approvers.push({ ...a, icon: 'file' });
            return;
          }

          isEnd = true;
          approvers.push({ ...a, icon: 'file' });
        });

        return {
          ...iteration,
          approvers,
          actualIteration: payload.length - 1 === idx
        };
      });
    }
  },

  actions: {
    fetchApproval: async function ({ commit, rootGetters }, { processId }) {
      const { data: process } = await this.$api.approval.getProcessById(processId);
      const { data: docs } = await this.$api.projectsV2.getProjectDocumentIndex(rootGetters['project/project']?.id, {
        params: {
          filter: {
            id: process.current_document_id
          }
        }
      });

      commit('UPDATE_ALL_DOCUMENTS', docs.data);
      commit('UPDATE_INFO', process);
      commit('UPDATE_ITERATIONS', process.iterations);

      return docs.data;
    },

    // NOT REVIEWED

    createProcess: async function(_, payload) {
      return this.$api.approval.createProcess(payload)
    },
    updateProcessByApprover: async function (_, { processId, payload }) {
      return this.$api.approval.update(processId, payload);
    },
    initApprovalProcess: async function (_, { processId, payload }) {
      return this.$api.approval.initProcess(processId, payload);
    },
    saveComment: async function (_, comment) {
      return this.$api.approval.comment(comment);
    },
    getAllProcesses: async function() {
      return await this.$api.approval.getProcesses();
    },
    resumeProcess: async function(_, { processId, payload }) {
      return await this.$api.approval.resumeProcess(processId, payload)
    }
  }
};
