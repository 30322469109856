var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"h-full",attrs:{"direction":"vertical"}},[(_vm.approvalDocument)?_c('page-header',{attrs:{"page-title":_vm.pageTitle},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('el-button',{on:{"click":_vm.goBack}},[_c('i',{staticClass:"el-icon-back"}),_vm._v(" К списку ")])]},proxy:true}],null,false,1488459133)},[(_vm.canEditDocument)?[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onOpenEditDocument}},[_vm._v(" Редактировать документ ")])]:_vm._e()],2):_vm._e(),(_vm.pdfUrl)?_c('div',{staticClass:"approval-pdf"},[_c('div',{staticClass:"approval-pdf__wrapper",attrs:{"id":"canvas-container"}}),_c('span',{staticClass:"el-image-viewer__btn el-image-viewer__close",on:{"click":function($event){_vm.pdfUrl = null}}},[_c('i',{staticClass:"el-icon-close"})])]):_vm._e(),_c('el-main',{staticClass:"pr-0 py-0"},[_c('el-row',{staticStyle:{"min-height":"100%"},attrs:{"type":"flex"}},[_c('el-col',{staticClass:"pr-4",attrs:{"span":18}},[_c('el-tabs',{on:{"tab-click":_vm.handleClickTab},model:{value:(_vm.activeTabName),callback:function ($$v) {_vm.activeTabName=$$v},expression:"activeTabName"}},[_c('el-tab-pane',{attrs:{"label":"Информация","name":"project.approval.info"}},[_c('el-row',{staticClass:"approval-iteration"},[(_vm.allDocuments)?_c('el-row',{staticClass:"row-gap-1.5 gap-0.75 flex-wrap--wrap",attrs:{"type":"flex","justify":"left","align":"center"}},_vm._l((_vm.getIterationDocs(_vm.currentIteration)),function(doc,idx){return _c('approval-card-item',{key:doc.id + 'approval.info' + idx,attrs:{"document":doc}})}),1):_vm._e(),(
                                    _vm.currentIteration.approvers &&
                                        !_vm.isAddApproverScreen &&
                                        (((_vm.approvalInfo.process_status ||
                                            _vm.isCreator ||
                                            _vm.isApprover) &&
                                            !_vm.isGip) ||
                                            (_vm.isGip &&
                                                _vm.approvalInfo.process_status !== 'not_initialized'))
                                )?_c('el-row',{staticClass:"approval-route"},[(_vm.currentIteration.approvers)?_c('div',{staticClass:"mb-3"},[_vm._v(" Маршрут согласования ")]):_vm._e(),_vm._l((_vm.currentIteration.approvers),function(approver,idx){return _c('approver',{key:approver.id + 'approval.info' + idx,attrs:{"approver":approver,"index":idx}})})],2):_vm._e()],1),(
                                _vm.approvalInfo.process_status === 'not_initialized' && _vm.isCreator
                            )?_c('el-button',{staticClass:"w-25",attrs:{"type":"danger","size":"medium"},on:{"click":function($event){return _vm.reject('stopped')}}},[_vm._v(" Остановить процесс согласования ")]):_vm._e(),(
                                (_vm.approvalInfo.process_status === 'not_initialized' ||
                                    _vm.isAddApproverScreen) &&
                                    !_vm.isCreator
                            )?_c('el-row',{staticClass:"w-50 _mt-1"},[_c('el-form',{ref:"reportForm",staticClass:"approval-form",attrs:{"model":_vm.form,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"Срок согласования","prop":"date"}},[_c('el-date-picker',{attrs:{"disabled":_vm.isApprover,"type":"date","format":"dd.MM.yyyy","value-format":"yyyy-MM-ddTHH:mm:ssZ","placeholder":"Выберите дату"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1),_vm._l((_vm.existingApprouvers),function(approver,idx){return _c('el-form-item',{key:approver.id + 'approval.info' + idx,attrs:{"label":`Согласующий ${idx + 1}`}},[_c('el-row',{staticClass:"f"},[_c('el-select',{staticClass:"width-full",attrs:{"value":_vm.showApproverLabel(approver),"disabled":"","size":"small"}})],1)],1)}),_vm._l((_vm.form.approvers),function(approver,index){return _c('el-form-item',{key:index + approver.id + 'approval.info' + index,staticClass:"approval-form__approvers"},[_c('user-field',{staticClass:"reset-el-form-item",attrs:{"label":`Согласующий ${
                                            _vm.existingApprouvers.length + 1 + index
                                        }`,"name":"approvers","value":approver.user,"is-document-approver":!_vm.isDisabledApprover(approver)},on:{"change":id => _vm.selectApprover(approver, id)}}),_c('el-button',{staticClass:"text-size--body border--none",attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.removeApprover(approver.id)}}},[_vm._v(" Удалить ")])],1)}),_c('el-row',{staticClass:"f _mr-5 f-x-end"},[_c('el-button',{staticClass:"approval-form__button",attrs:{"type":"primary","size":"small","icon":"el-icon-plus","disabled":!_vm.isGip &&
                                                _vm.approvalInfo.process_status === 'not_initialized'},on:{"click":_vm.addApproverToForm}},[_vm._v(" Добавить ")])],1)],2),(!_vm.isAddApproverScreen)?_c('el-checkbox',{staticClass:"_mt-1",model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_c('span',{staticClass:"approval-agreement"},[_vm._v("Нажимая кнопку “Создать процесс” я подтверждаю, что документация соответствует стадии \"П\" и заданию на проектирование.")])]):_vm._e(),_c('el-divider',{staticClass:"_my-1.25"}),_c('div',{staticClass:"f"},[(!_vm.isCreator && _vm.isAddApproverScreen)?_c('el-button',{staticClass:"text-size--body",attrs:{"type":"info","size":"small"},on:{"click":function($event){_vm.isAddApproverScreen = false}}},[_vm._v(" Назад ")]):_vm._e(),(
                                        _vm.isGip && _vm.approvalInfo.process_status === 'not_initialized'
                                    )?_c('el-button',{staticClass:"text-size--body",attrs:{"type":"primary","size":"small"},on:{"click":_vm.initProcess}},[_vm._v(" Создать процесс ")]):_c('el-button',{staticClass:"w-full text-size--body",attrs:{"type":"primary","disabled":_vm.approvalInfo.process_status === 'not_initialized',"size":"small"},on:{"click":_vm.addApproverToProcess}},[_vm._v(" Сохранить ")])],1)],1):(
                                (_vm.isGip &&
                                    _vm.approvalInfo.process_status !== 'stopped' &&
                                    !_vm.isBtnsDisabled) ||
                                    (_vm.isApprover &&
                                        _vm.approvalInfo.process_status !== 'stopped' &&
                                        _vm.approvalInfo.process_status === 'on_review')
                            )?_c('el-row',{staticClass:"w-45 _mb-1"},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2 },"disabled":_vm.isBtnsDisabled,"maxlength":"200","show-word-limit":"","placeholder":"Введите комментарий...","autocomplete":"off"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('el-checkbox',{staticClass:"_mt-1",attrs:{"disabled":_vm.isBtnsDisabled},model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_c('span',{staticClass:"approval-agreement"},[_vm._v("Нажимая кнопку “Согласовать” я подтверждаю, что документация соответствует стадии \"П\" и заданию на проектирование.")])]),_c('el-divider',{staticClass:"_my-1.25"}),_c('el-row',{staticClass:"f"},[_c('el-button',{staticClass:"text-size--body",staticStyle:{"flex":"1"},attrs:{"disabled":_vm.isBtnsDisabled,"type":"primary","size":"small"},on:{"click":_vm.approve}},[_vm._v(" Согласовать ")]),_c('el-button',{staticClass:"text-size--body",staticStyle:{"flex":"1"},attrs:{"disabled":_vm.isBtnsDisabled,"type":"danger","size":"small"},on:{"click":function($event){return _vm.reject('canceled')}}},[_vm._v(" Отклонить ")]),_c('el-button',{staticClass:"text-size--body",staticStyle:{"flex":"1"},attrs:{"disabled":_vm.isBtnsDisabled,"size":"small"},on:{"click":_vm.switchAddApproverScreen}},[_vm._v(" Добавить согласующего ")])],1)],1):(
                                !_vm.isCreator && _vm.approvalInfo.process_status === 'stopped'
                            )?_c('el-row',[_c('div',{staticClass:"text-size--supplementary"},[_vm._v(" Процесс остановлен проектировщиком ")])]):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"Архив","name":"project.approval.archive"}},_vm._l((_vm.iterations),function(iteration,index){return _c('el-row',{key:iteration.id + 'approval.archive' + index,staticClass:"approval-iteration"},[_c('div',{staticClass:"_mb-0.5 text-size--supplementary",staticStyle:{"color":"#606266"}},[_vm._v(" "+_vm._s(index === _vm.iterations.length - 1 ? "Актуальная итерация:" : `Итерация ${index + 1}:`)+" ")]),(_vm.allDocuments)?_c('el-row',{staticClass:"row-gap-1.5 gap-0.75 flex-wrap--wrap",attrs:{"type":"flex","justify":"left","align":"center"}},_vm._l((_vm.getIterationDocs(iteration)),function(doc){return _c('approval-card-item',{key:doc.id + 'approval.archive' + iteration.id,attrs:{"document":doc}})}),1):_vm._e(),(
                                    iteration.approvers &&
                                        !_vm.isAddApproverScreen &&
                                        (_vm.approvalInfo.process_status || _vm.isCreator)
                                )?_c('el-row',{staticClass:"approval-route"},[(_vm.isCreator)?_c('div',{staticClass:"mb-3"},[_vm._v(" Маршрут согласования ")]):_vm._e(),_vm._l((iteration.approvers),function(approver,idx){return _c('approver',{key:approver.id + 'approval.archive' + idx,attrs:{"approver":approver,"index":idx}})})],2):_vm._e(),_c('el-divider',{staticClass:"approval-iteration__divider _mb-1.25 _mt-1.75"})],1)}),1)],1)],1),_c('el-col',{staticClass:"d-flex flex--grow",attrs:{"span":7}},[_c('el-card',{staticClass:"w-full border--none",attrs:{"body-style":{ padding: '16px' },"shadow":"always"}},[_c('approve-description',{attrs:{"info":_vm.approvalInfo,"version":_vm.currentIteration.document_version,"get-pdf-data":_vm.getPdfData,"gip-or-approver":_vm.gipOrApprover,"doc":_vm.approvalDocument,"project-name":_vm.projectName},on:{"changeLoading":_vm.changeLoading}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }