export default (axios) => ({
  getInfo: ({ approvalProcessId }) => {
    return axios.get(`approvals/${approvalProcessId}`);
  },
  update: (approvalProcessId, payload) => {
    return axios.put(`approvals/${approvalProcessId}/change-status`, payload)
  },
  initProcess: (approvalProcessId, payload) => {
    return axios.put(`approvals/${approvalProcessId}`, payload)
  },
  createProcess: (payload) => {
    return axios.post('approvals', payload)
  },
  comment: (payload) => {
    return axios.post('comments', payload)
  },
  getProcesses: () => {
    return axios.post('approvals/get-all', {})
  },
  getProcessById: (processId) => {
    return axios.get(`approvals/${processId}`)
  },
  resumeProcess: (processId, payload) => {
    return axios.put(`approvals/${processId}/resume`, payload)
  }
});
